<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">Main Contact</v-card-title>
        <v-card-text>
            <v-form ref="mainContact">
                <v-autocomplete
                    @change="deleteSearch"
                    :search-input.sync="search"
                    label="Main Contact"
                    v-model="mainContact"
                    :items="users"
                    item-text="name"
                    chips
                    item-value="id"
                >
                </v-autocomplete>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn text color="secondary" @click="closeDialog">
                Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="update" text color="error" :loading="loading">
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import { loadImage } from '@/helpers/imageHandler'
import API from '@/services/api'

export default {
    name: 'ChangeMainContact',
    props: {
        quoteId: {
            type: String,
            required: true,
            default: () => {
                return ''
            },
        },
        quote: Object,
    },
    data: () => {
        return {
            mainContact: null,
            search: '',
            loading: false,
            errMessage: '',
            companyId: JSON.parse(localStorage.getItem('company')),
            folderUsersPictures: 'users_pictures',
            users: [],
        }
    },
    async mounted() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        closeDialog() {
            this.$emit('closeChangeMainContact')
        },
        deleteSearch() {
            this.search = ''
        },
        async update() {
            try {
                this.loading = true
                this.errMessage = ''
                const user = this.users.find(u => u.id === this.mainContact)
                if (user.picture) {
                    user.profilePicURL = await loadImage(
                        `${this.companyId}/${this.folderUsersPictures}`,
                        user.picture
                    )
                }
                this.quote.collaborators.push(this.mainContact)
                this.quote.collaborators = this.quote.collaborators.filter(
                    collaborator => collaborator != this.quote.userId
                )
                await API.changeMainContact(this.quoteId, {
                    userId: user.id,
                    collaborators: this.quote.collaborators,
                    notificationId: 'EgV5SsjEOeHy3e9DQkbM',
                })
                this.$emit('replaceMainContact', user)
                this.$emit('closeChangeMainContact')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
